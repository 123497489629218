// @ts-nocheck
import React from 'react'
import styled from 'styled-components'
import { formatPrice } from '../../utils/utils'
import { PropTypes } from 'prop-types'

/**
 * The styles for this component are provided by the calling component
 */

const StrikeoutPrice = styled.span`
	margin-inline-start: 5px;
	display: inline-block;
	color: var(--inactive);
	text-decoration: line-through;
`

const ItemPrice = (props) => {
	const { displayPrice, price, currency, countryCode, strikeoutPrice, hideMenuItemPrice } = props

	if (hideMenuItemPrice) {
		return <span />
	}

	return (
		<>
			<span data-testid="menu-item-price">{displayPrice || formatPrice(price, currency, countryCode)}</span>
			{!!strikeoutPrice && (
				<StrikeoutPrice data-testid="menu-item-strikeout-price">{formatPrice(strikeoutPrice, currency, countryCode)}</StrikeoutPrice>
			)}
		</>
	)
}

ItemPrice.propTypes = {
	hideMenuItemPrice: PropTypes.bool,
	displayPrice: PropTypes.string,
	price: PropTypes.number,
	currency: PropTypes.string,
	countryCode: PropTypes.string,
	strikeoutPrice: PropTypes.number,
}

export default ItemPrice
